import React, { useReducer, useEffect, useContext } from "react";
import Card from "./Card";
import { fetchData, contentReducer, initialStateContent } from "../utils/fetchData";
import { LanguageContext } from "../App";
import linkNames from "../utils/linkNames";

import weldingMag from "../assets/images/welding-mag.jpg";
import weldingTig from "../assets/images/welding-tig.jpg";
import weldingMig from "../assets/images/welding-mig.jpg";
import weldingMma from "../assets/images/welding-mma.jpg";
import weldingCompanies from "../assets/images/welding-companies.jpg";
import weldingUnemployed from "../assets/images/welding-unemployed.jpg";
import weldingQualification from "../assets/images/welding-qualification.jpg";
import weldingChoose from "../assets/images/welding-choose.jpg";

const imageMap: { [key: string]: string } = {
  "welding-mag.jpg": weldingMag,
  "welding-tig.jpg": weldingTig,
  "welding-mig.jpg": weldingMig,
  "welding-mma.jpg": weldingMma,
  "welding-companies.jpg": weldingCompanies,
  "welding-unemployed.jpg": weldingUnemployed,
  "welding-qualification.jpg": weldingQualification,
  "welding-choose.jpg": weldingChoose,
};

const Container: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const [state, dispatch] = useReducer(contentReducer, initialStateContent);
  const links = linkNames[language];
 
  useEffect(() => {
    const dbFileName: string = "courses";

    fetchData(language, dbFileName, dispatch);
  }, [language]);

  const { contentDataArray, error, loading } = state;

  return (
    <div className="card-container">
      {loading && <div>Loading...</div>}
      {error && <div>{error}</div>}
      {!loading && !error && contentDataArray.length === 0 && (
        <div>No data available.</div>
      )}
      {contentDataArray.map((item) => {
        
        const linkId = links[item.id as keyof typeof links]; 

        return (
          <Card
            key={item.id}
            link={`/${language}/${links.courses}/${linkId}`}
            image={imageMap[item.image] || item.image}
            title={item.title}
            description={item.description}
          />
        );
      })}
    </div>
  );
};

export default Container;


